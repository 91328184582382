import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Markdown from '../components/Markdown';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Layout from '../components/Layout';
import Link from '../components/Link';

import logo from '../img/logo.svg';
import logoWhite from '../img/logo-white.svg';
import './index-page.sass';

const Section = ({ id, sectionStyle, containerStyle, children }) => (
  <section className="section" style={sectionStyle} id={id}>
    <div className="container" style={containerStyle}>
      {children}
    </div>
  </section>
);

export const IndexPageTemplate = ({
  hero,
  leadVideo,
  mainpitch,
  benefits,
  becomingAMember,
  readMore,
  footer
}) => (
  <>
    {hero.isActive && (
      <div className="hero is-dark is-bold" style={{ paddingTop: '2.5rem' }}>
        <aside
          className="hero-bg"
          style={{
            opacity: 0.2,
            overflow: 'hidden'
          }}
        >
          <PreviewCompatibleImage imageInfo={{ image: hero.image }} />
        </aside>
        <div className="hero-body">
          <div className="container">
            <h1
              className="title is-size-2-mobile has-font-family-condensed"
              style={{ fontSize: '3.5rem' }}
            >
              <span className="hero-logo">
                <img
                  src={logoWhite}
                  alt="BGA"
                  style={{
                    display: 'inline',
                    fontSize: 'inherit',
                    width: '3.1ch',
                    verticalAlign: 'baseline',
                    position: 'relative',
                    bottom: '-.025ex'
                  }}
                />
              </span>{' '}
              members <br />
              demand{' '}
              <span className="rotate-words highlight has-text-primary">
                <span
                  className="rotate-words-placeholder"
                  style={{ visibility: 'hidden' }}
                >
                  accountability.
                </span>
                <span className="rotate-words-item">transparency.</span>
                <span className="rotate-words-item">accountability.</span>
                <span className="rotate-words-item">efficiency.</span>
              </span>
            </h1>
            <h2
              className="subtitle is-size-4 is-size-6-mobile"
              style={{
                maxWidth: '600px',
                marginTop: '.25em',
                marginBottom: '1.5em'
              }}
            >
              {hero.subheading}
            </h2>
            <Link
              className="button is-primary is-uppercase has-text-weight-bold has-font-family-condensed"
              style={{ alignSelf: 'flex-start', fontSize: '1.4rem' }}
              to={hero.button.path}
            >
              {hero.button.text}
            </Link>
          </div>
        </div>
      </div>
    )}

    {/* Main pitch */}
    {mainpitch.isActive && (
      <Section id="mainpitch">
        <h2 className="title">{mainpitch.title}</h2>
        <Markdown>{mainpitch.description}</Markdown>
      </Section>
    )}

    {/* Lead video */}
    {leadVideo.isActive && (
      <Section containerStyle={{ maxWidth: '900px' }} id="lead-video">
        <div className="columns">
          <div className="column is-two-thirds is-full-width-mobile">
            {/* Responsive video embed styles via https://embedresponsively.com/
                and https://stackoverflow.com/a/55096449 */}
            <div
              className="embed-container"
              style={{
                position: 'relative',
                paddingBottom: '56.25%',
                height: 0,
                overflow: 'hidden',
                maxWidth: '100%'
              }}
            >
              <iframe
                title="bga-lead-video"
                src={leadVideo.videoSrc}
                frameborder="0"
                allowfullscreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  outline: '2px solid white',
                  outlineOffset: '-1px'
                }}
              />
            </div>
          </div>
          <div
            className="column is-one-third align-right-tablet"
            style={{ alignSelf: 'center' }}
          >
            <h2 className="title is-inline-block is-uppercase is-size-5">
              {leadVideo.title}
            </h2>
            <Markdown>{leadVideo.description}</Markdown>
          </div>
        </div>
      </Section>
    )}

    {/* Member benefits */}
    {benefits.isActive && (
      <Section id="benefits">
        <h2 className="title is-inline-block is-uppercase is-size-5 is-outlined">
          {benefits.title}
        </h2>
        <Markdown>{benefits.description}</Markdown>
      </Section>
    )}

    {/* Becoming a member */}
    {becomingAMember.isActive && (
      <Section containerStyle={{ maxWidth: '900px' }} id="becoming-a-member">
        <h2 className="title is-size-5 has-text-weight-light">
          {becomingAMember.title}
        </h2>
        <div className="columns">
          {becomingAMember.boxes.map((box, idx) => (
            <div className="column" key={idx}>
              <div className="box">
                <h3 className="is-size-3 has-text-weight-bold">{box.title}</h3>
                <Markdown>{box.description}</Markdown>
                <div
                  className="product-image"
                  style={{
                    height: '240px',
                    width: '300px',
                    maxWidth: '100%',
                    display: 'inline-block'
                  }}
                >
                  <PreviewCompatibleImage imageInfo={box} />
                </div>
                <Link className="button is-success is-italic" to={box.btnPath}>
                  {box.btnText}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Section>
    )}

    {/* Need more convincing? */}
    {readMore.isActive && (
      <Section id="need-more-convincing">
        <h2 className="title">{readMore.title}</h2>
        <div className="columns">
          {readMore.buttons.map(({ path, text }, idx) => (
            <div className="column is-4" key={idx}>
              <a
                className="button is-medium is-primary is-inverted is-outlined"
                href={path}
              >
                {text}
              </a>
            </div>
          ))}
        </div>
      </Section>
    )}

    {/* Footer */}
    {footer.isActive && (
      <Section id="about-bga">
        <hr style={{ backgroundColor: 'rgba(128, 128, 128, 0.1)' }} />
        <img
          src={logo}
          alt="BGA"
          style={{ width: '88px', marginBottom: '1rem' }}
        />
        <Markdown
          remarkReactOptions={{
            remarkReactComponents: {
              p: props => (
                <p
                  style={{ marginBottom: '1rem' }}
                  className="is-italic has-text-grey"
                >
                  {props.children}
                </p>
              )
            }
          }}
        >
          {footer.text}
        </Markdown>
      </Section>
    )}
  </>
);

IndexPageTemplate.propTypes = {
  hero: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    subheading: PropTypes.string,
    button: PropTypes.object
  }),
  leadVideo: PropTypes.object,
  mainpitch: PropTypes.object,
  benefits: PropTypes.object,
  becomingAMember: PropTypes.shape({
    title: PropTypes.string,
    boxes: PropTypes.array
  }),
  readMore: PropTypes.shape({
    title: PropTypes.string,
    buttons: PropTypes.array
  }),
  footer: PropTypes.shape({
    text: PropTypes.string
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero {
          isActive
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          subheading
          button {
            text
            path
          }
        }
        mainpitch {
          isActive
          title
          description
        }
        leadVideo {
          isActive
          videoSrc
          title
          description
        }
        benefits {
          isActive
          title
          description
        }
        becomingAMember {
          isActive
          title
          boxes {
            title
            description
            image {
              childImageSharp {
                fluid(
                  maxWidth: 300
                  maxHeight: 240
                  fit: CONTAIN
                  background: "#ffffff00"
                  quality: 80
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            btnText
            btnPath
          }
        }
        readMore {
          isActive
          title
          buttons {
            text
            path
          }
        }
        footer {
          isActive
          text
        }
      }
    }
  }
`;
