import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import react from 'remark-react';

const Markdown = ({ children, remarkReactOptions }) =>
  remark()
    .use(recommended)
    .use(react, { sanitize: true, ...remarkReactOptions })
    .processSync(children).contents;

export default Markdown;
